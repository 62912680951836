import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { AppLayout } from "../component/appLayout/AppLayout";
import { useDispatch } from "react-redux";
import { authenticationHandler, clearLoginData } from "../redux/slice/login";
import { emitter } from "../utils/eventEmitter";
import { getPermissionsThunk } from "../redux/thunk/login";

const PrivateRoute = ({ children, isAuthenticated }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("id")) dispatch(getPermissionsThunk(localStorage.getItem("id")));
  }, [localStorage.getItem("id")]);

  useEffect(() => {
    emitter.on("logout", () => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("userRole");
      dispatch(clearLoginData());
      dispatch(authenticationHandler(false));
    });
  }, [emitter]);

  return isAuthenticated ? <AppLayout>{children}</AppLayout> : <Navigate to="/" />;
};

export default PrivateRoute;
