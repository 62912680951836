import { Dialog, DialogBody, DialogHeader } from "@material-tailwind/react";
import { viewPrescriptionHistoryHeading, viewPrescriptionHistoryKeys } from "../../common/ActionTable/TableConstants";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { ActionTable } from "../../common/ActionTable/ActionTable";
import { useState } from "react";

const ViewPrescription = ({ open, toggler, appointment }) => {
  // eslint-disable-next-line
  const [selectedRow, setSelectedRow] = useState();
  const actions = [{ icon: <MdOutlineRemoveRedEye size={20} color="green" className="cursor-pointer" />, tooltip: "View", onClickFunction: (data) => window.open(data?.url) }];

  return (
    <Dialog open={open} handler={toggler}>
      <DialogHeader>Prescription History</DialogHeader>
      <DialogBody className="flex justify-center items-center flex-col gap-4">
        <ActionTable heading={viewPrescriptionHistoryHeading} keys={viewPrescriptionHistoryKeys} tableData={appointment?.prescriptionPdfHistory} actions={actions} selectedRow={setSelectedRow} />
      </DialogBody>
    </Dialog>
  );
};

export default ViewPrescription;
