import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogBody, DialogHeader, Textarea } from "@material-tailwind/react";
import { useDispatch } from "react-redux";
import { appointmentThunk, notesThunk } from "../../../redux/thunk/appointments";
import { getPatientThunk } from "../../../redux/thunk/patients";

const Notes = ({ open, toggler, appointment, patient, currentPage, searchValue, payloadValue }) => {
  const [note, setNote] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if ((appointment?.patient && appointment?.patient[0]?.appointmentNotes) || patient?.appointmentNotes) setNote(appointment?.patient[0]?.appointmentNotes || patient?.appointmentNotes);
    else setNote("");
  }, [appointment, patient]);

  const notesHandler = async () => {
    await dispatch(notesThunk({ patientId: patient?._id ? patient._id : appointment.patient[0]._id, note: note }));
    if (appointment?.patient && appointment?.patient[0]) dispatch(appointmentThunk({ ...payloadValue, pageSize: 25, pageIndex: currentPage }));
    else dispatch(getPatientThunk({ value: searchValue, pageSize: 25, pageIndex: currentPage }));
    toggler();
  };

  return (
    <Dialog open={open} handler={toggler}>
      <DialogHeader>Add Note</DialogHeader>
      <DialogBody>
        <Textarea label="Note" id="note" rows={10} value={note} onChange={(e) => setNote(e.target.value)} color="blue" placeholder="Enter Notes" />
        <div className="lg:col-span-2 flex justify-center gap-4">
          <Button onClick={notesHandler} color="indigo" type="submit">
            Submit
          </Button>
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default Notes;
