import React from 'react'
//import UploadForm from './_components/UploadForm'
import UploadContainer from './_components/uploadContainer'


function FileUpload() {
  return (
    <div className='p-5 px-8 md: px-28'>
      
    <UploadContainer/>
    </div>
  )
}

export default FileUpload
