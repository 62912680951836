import React, { useState, useEffect } from "react";
import { Container, Typography, TextField, Button, Checkbox, Table, TableHead, TableBody, TableRow, TableCell, Box, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPatientThunk } from "../../redux/thunk/patients";
import { DefaultPagination } from "../../utils/pagination";
import axios from "axios";
import { baseURL } from "../../component/constants/defaultValues";
import { toast } from "react-toastify";

const MessageBroadcasting = () => {
  const [template, setTemplate] = useState("");
  const [message, setMessage] = useState({
    content: "",
    border: false
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchingPatients, setFetchingPatients] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [selectedCount, setSelectedCount] = useState(0); // State for selected count
  const pageSize = 10;
  const dispatch = useDispatch();
  const patientData = useSelector((state) => state.PatientSlice);

  const templates = [
    {
      id: "broadcast_message",
      label: "Broadcast Message",
      content: `Hi {{patientName}} ! 

We are glad to inform you that you have been successfully registered with Hospital name. 
Here are your appointment details : 
Date: some date
Time: some time
Assigned Doctor: Doctor name

Have a nice day!`,
      editable: true
    }
  ];

  useEffect(() => {
    setFetchingPatients(true);
    dispatch(getPatientThunk({ value: searchQuery, pageSize, pageIndex: currentPage })).finally(() => setFetchingPatients(false));
  }, [dispatch, currentPage, searchQuery]);

  useEffect(() => {
    const currentPatients = patientData?.patientList?.allPatients?.patients || [];
    const currentPatientIds = currentPatients.map((patient) => patient._id);
    const allSelected = currentPatientIds.every((id) => selectedPatients.includes(id));
    const noneSelected = currentPatientIds.every((id) => !selectedPatients.includes(id));

    if (allSelected) {
      setSelectAll(true);
    } else if (noneSelected) {
      setSelectAll(false);
    } else {
      setSelectAll(false); // Partially selected, default to false
    }

    setSelectedCount(selectedPatients.length); // Update selected count
  }, [selectedPatients, patientData, currentPage]);

  const handlePatientSelection = (selectedPatientId) => {
    setSelectedPatients((prevPatients) => {
      const newSelectedPatients = prevPatients.includes(selectedPatientId) ? prevPatients.filter((id) => id !== selectedPatientId) : [...prevPatients, selectedPatientId];
      return newSelectedPatients;
    });
  };

  const handleSelectAll = () => {
    const currentPatients = patientData?.patientList?.allPatients?.patients || [];
    const currentPatientIds = currentPatients.map((patient) => patient._id);

    if (selectAll) {
      setSelectedPatients((prevPatients) => prevPatients.filter((id) => !currentPatientIds.includes(id)));
    } else {
      setSelectedPatients((prevPatients) => [...prevPatients, ...currentPatientIds.filter((id) => !prevPatients.includes(id))]);
    }
    setSelectAll((prevSelectAll) => !prevSelectAll);
  };

  const handleBroadcast = async () => {
    if (selectedPatients.length === 0) {
      toast.error("Please select at least one patient to broadcast the message.");
      return;
    }

    const patientNames = selectedPatients
      .map((id) => {
        const patient = patientData?.patientList?.allPatients?.patients.find((p) => p._id === id);
        return patient || null; // Return the patient object or null if not found
      })
      .filter((patient) => patient !== null); // Filter out null values if needed

    const finalMessage = `Hi ${patientNames}, ${message.content} Thanks`;

    const payload = {
      patientIds: selectedPatients,
      campaignName: "Default",
      doctorId: "65acd2f5d8e7a673531c34ac",
      message: finalMessage
    };

    setLoading(true);

    try {
      const res = await axios.post(baseURL + `/broadcast/broadcastMsg`, payload);
      toast.success("Message broadcasted successfully!");
      console.log(res.data);
      setSelectedPatients([]); // Deselect all patients
      setSelectAll(false); // Reset Select All state
    } catch (error) {
      console.error("Error broadcasting message:", error);
      toast.error("Failed to broadcast message.");
    } finally {
      setLoading(false);
    }
  };

  const handleMessageChange = (event) => {
    setMessage({ ...message, content: event.target.value });
  };

  const handleMessageClick = () => {
    setMessage((prev) => ({ ...prev, border: true }));
  };

  const handleTemplateChange = (event) => {
    const selectedTemplate = templates.find((t) => t.id === event.target.value);
    if (selectedTemplate) {
      setTemplate(selectedTemplate.id);
      setMessage({ content: selectedTemplate.content, editable: selectedTemplate.editable });
    }
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
    setSelectAll(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  const totalPages = patientData?.patientList?.allPatients?.count ? Math.ceil(patientData.patientList.allPatients.count / pageSize) : 0;

  return (
    <Container
      style={{
        background: "#ffffff",
        borderRadius: "8px",
        marginTop: "2rem",
        padding: "2rem",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
      }}
    >
      <Typography variant="h4" gutterBottom align="center" style={{ marginBottom: "1rem", fontWeight: 600 }}>
        Message Broadcasting
      </Typography>

      <TextField select label="Select Template" value={template} onChange={handleTemplateChange} fullWidth margin="normal" variant="outlined">
        {templates.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Box position="relative" marginBottom="1rem">
        <Typography
          variant="caption"
          style={{
            position: "absolute",
            top: "8px",
            left: "8px",
            background: "#ffffff",
            padding: "0 4px",
            fontWeight: 500,
            color: "#7D55F3",
            zIndex: 1
          }}
        >
          Message
        </Typography>
        <TextField
          label=""
          placeholder="Type your message here"
          value={message.content}
          onChange={handleMessageChange}
          fullWidth
          margin="normal"
          variant="outlined"
          multiline
          rows={4}
          onClick={handleMessageClick}
          InputProps={{
            style: {
              paddingTop: "24px",
              paddingBottom: "8px"
            }
          }}
          style={{
            border: message.border ? "2px solid #7D55F3" : "1px solid #ccc",
            borderRadius: "4px"
          }}
          disabled={true}
        />
      </Box>

      <Box marginBottom="1rem">
        <TextField label="Search" value={searchQuery} onChange={handleSearchChange} margin="normal" variant="outlined" className="w-1/2 " />
      </Box>

      <Box display="flex" alignItems="center" marginBottom="1rem">
        <Button variant="outlined" onClick={handleSelectAll} className="border-[#7D55F3] text-[#7D55F3] hover:bg-[#7D55F3] w-fit">
          {selectAll ? "Deselect All" : "Select All"}
        </Button>
        <Typography variant="body2" style={{ marginLeft: "auto", fontSize: "1.5rem", textAlign: "right", display: "block" }}>
          {selectedCount} : Selected
        </Typography>
      </Box>

      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: "#7D55F3" }}>
            <TableCell
              style={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                color: "white",
                padding: "8px 16px"
              }}
            ></TableCell>

            <TableCell
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                color: "white",
                padding: "8px 16px"
              }}
            >
              Patient's Name
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                color: "white",
                padding: "8px 16px"
              }}
            >
              Phone No.
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                color: "white",
                padding: "8px 16px"
              }}
            >
              Gender
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                color: "white",
                padding: "8px 16px"
              }}
            >
              State
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                color: "white",
                padding: "8px 16px"
              }}
            >
              City
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                color: "white",
                padding: "8px 16px"
              }}
            >
              Pin
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                color: "white",
                padding: "8px 16px"
              }}
            >
              Department
            </TableCell>
            <TableCell
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                color: "white",
                padding: "8px 16px"
              }}
            >
              Date Of Birth
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fetchingPatients ? (
            <TableRow>
              <TableCell colSpan={8} align="center">
                Loading...
              </TableCell>
            </TableRow>
          ) : (
            patientData?.patientList?.allPatients?.patients
              ?.filter((patient) => {
                // Convert fields to lowercase strings for comparison
                const name = String(patient.name || "").toLowerCase();
                const state = String(patient.state || "").toLowerCase();
                const city = String(patient.city || "").toLowerCase();
                const pincode = String(patient.pincode || "").toLowerCase();
                const gender = String(patient.gender || "").toLowerCase();
                const departments = patient.departments?.map((department) => String(department.name || "").toLowerCase()) || [];

                // Check if searchQuery is included in any of the fields
                return (
                  name.includes(searchQuery.toLowerCase()) ||
                  state.includes(searchQuery.toLowerCase()) ||
                  pincode.includes(searchQuery.toLowerCase()) || // Compare as string
                  departments.some((department) => department.includes(searchQuery.toLowerCase())) ||
                  pincode.includes(searchQuery.toLowerCase()) ||
                  departments.some((department) => department.includes(searchQuery.toLowerCase())) ||
                  gender.includes(searchQuery.toLowerCase()) ||
                  city.includes(searchQuery.toLowerCase())
                );
              })
              .map((patient) => (
                <TableRow key={patient._id} hover onClick={() => handlePatientSelection(patient._id)} style={{ cursor: "pointer", backgroundColor: selectedPatients.includes(patient._id) ? "#f0f0f0" : "transparent" }}>
                  <TableCell>
                    <Checkbox
                      checked={selectedPatients.includes(patient._id)}
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePatientSelection(patient._id);
                      }}
                      color="primary"
                    />
                  </TableCell>
                  <TableCell>{patient.name}</TableCell>
                  <TableCell>{patient.phone}</TableCell>
                  <TableCell>{patient.gender}</TableCell>
                  <TableCell>{patient.state}</TableCell>
                  <TableCell>{patient.city}</TableCell>
                  <TableCell>{patient.pincode}</TableCell>
                  <TableCell>{patient.departments.map((department) => department.name).join(", ")}</TableCell>
                  <TableCell>
                    {new Date(patient.dob).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                      year: "numeric"
                    })}
                  </TableCell>
                </TableRow>
              ))
          )}
        </TableBody>
      </Table>

      {/* Centered Pagination and Broadcast Button */}
      <Box className="flex flex-col items-center mt-4 space-y-4">
        <DefaultPagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />

        <Button variant="contained" color="primary" onClick={handleBroadcast} disabled={loading} className="w-fit">
          {loading ? "Broadcasting..." : "Broadcast Message"}
        </Button>
      </Box>
    </Container>
  );
};

export default MessageBroadcasting;
