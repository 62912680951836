import { Button, Checkbox, Dialog, DialogBody, DialogFooter, DialogHeader, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, updatePermissions } from "../../../redux/thunk/user";
import { toast } from "react-toastify";
import { getPermissionsThunk } from "../../../redux/thunk/login";

const PermissionModal = ({ open, toggler, user, currentPage, pageSize }) => {
  const [currentUserPermission, setCurrentUserPermissions] = useState([]);

  const dispatch = useDispatch();
  const permissionsList = useSelector((state) => state.UserSlice.allPermissions);
  const extractSubmodules = (data = []) => {
    let submoduleKeys = [];
    data.forEach((module) => {
      module.submodules.forEach((submodule) => {
        submoduleKeys.push(submodule);
      });
    });
    return submoduleKeys;
  };

  useEffect(() => {
    if (user.permissions) setCurrentUserPermissions(user.permissions);
  }, [user]);

  const updatePermissionHandler = (moduleIndex, subModuleIndex, isChecked) => {
    let temp = JSON.parse(JSON.stringify(currentUserPermission));
    if (temp.length === 0) {
      temp = [
        {
          moduleKey: "PATIENTS",
          submodules: []
        },
        {
          moduleKey: "APPOINTMENTS",
          submodules: []
        },
        {
          moduleKey: "MESSAGE_BROADCASTING",
          submodules: []
        },
        {
          moduleKey: "CUSTOMER_SUPPORT",
          submodules: []
        },
        {
          moduleKey: "USER_CONTROL",
          submodules: []
        }
      ];
    }
    if (isChecked) {
      const submoduleKeyToRemove = permissionsList[moduleIndex].submodules[subModuleIndex].submoduleKey;
      temp[moduleIndex].submodules = temp[moduleIndex].submodules.filter((submodule) => submodule !== submoduleKeyToRemove);
    } else {
      const submoduleKeyToAdd = permissionsList[moduleIndex].submodules[subModuleIndex].submoduleKey;
      temp[moduleIndex].submodules.push(submoduleKeyToAdd);
    }

    setCurrentUserPermissions(temp);
  };

  const submitHandler = () => {
    dispatch(updatePermissions({ _id: user._id, body: { permissionArray: currentUserPermission } }))
      .unwrap()
      .then((data) => {
        if (data?.success) {
          toast.success(data?.msg);
          dispatch(getAllUsers({ currentPage, pageSize }));
          if (localStorage.getItem("id")) dispatch(getPermissionsThunk(localStorage.getItem("id")));
          toggler();
          window.location.reload();
        }
      });
  };
  return (
    <Dialog open={open} handler={toggler} className="w-full">
      <DialogHeader className="flex justify-center">
        Permissions for <span className="text-blue-600 font-bold pl-2 uppercase">{user?.name}</span>
      </DialogHeader>
      <DialogBody className="flex flex-wrap justify-between gap-10 px-10 h-80 overflow-auto">
        {permissionsList &&
          permissionsList.map((module, mi) => {
            return (
              <div key={module.moduleKey} className="w-full">
                <Typography className="text-center font-bold text-red-700 mb-6 underline">{module.moduleName}</Typography>
                <div>
                  {module.submodules &&
                    module.submodules.map((subModule, si) => {
                      const isChecked = extractSubmodules(currentUserPermission).includes(subModule.submoduleKey);
                      return (
                        <div key={subModule._id} className="flex justify-between">
                          <Typography className="font-semibold text-black">{subModule.submoduleName}</Typography>
                          <Checkbox onChange={() => updatePermissionHandler(mi, si, isChecked)} color="green" checked={isChecked} />
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </DialogBody>
      <DialogFooter className="flex justify-center">
        <Button color="red" onClick={() => toggler()} className="mr-3">
          cancel
        </Button>
        <Button color="indigo" onClick={submitHandler}>
          Submit
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default PermissionModal;
