import { Button, Input, Typography } from "@material-tailwind/react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CreatableSelect from "react-select/creatable";
import Logo from "../../assets/robodoc.png";
import { FaTrash } from "react-icons/fa";
import { invoicethunk } from "../../redux/thunk/invoice";

const Invoice = () => {
  const [invoiceItems, setInvoiceItems] = useState([{ description: "", quantity: 1, price: 0 }]);
  const [invoiceDetails, setInvoiceDetails] = useState({
    customerName: "",
    mobile: "",
    address: "",
    invoicedate: "",
    duedate: ""
  });
  const [discount, setDiscount] = useState(5);
  const [options, setOptions] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState(100000);

  const dispatch = useDispatch();

  useEffect(() => {
    // Load the last invoice number from local storage if available
    const lastInvoiceNumber = localStorage.getItem("lastInvoiceNumber");
    if (lastInvoiceNumber) {
      setInvoiceNumber(parseInt(lastInvoiceNumber, 10) + 1);
    }
  }, []);

  const handleInvoiceDetailChange = (e) => {
    const { name, value } = e.target;
    setInvoiceDetails({ ...invoiceDetails, [name]: value });
  };

  const handleItemChange = (index, name, value) => {
    const updatedItems = [...invoiceItems];
    updatedItems[index][name] = value;
    setInvoiceItems(updatedItems);
  };

  const handleSelectChange = (index, newValue) => {
    handleItemChange(index, "description", newValue ? newValue.label : "");
  };

  const handleCreate = (index, inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    setOptions([...options, newOption]);
    handleSelectChange(index, newOption);
  };

  const addItem = () => {
    setInvoiceItems([...invoiceItems, { description: "", quantity: 1, price: 0 }]);
  };

  const removeItem = (index) => {
    const updatedItems = invoiceItems.filter((_, i) => i !== index);
    setInvoiceItems(updatedItems);
  };

  const calculateTotal = () => {
    return invoiceItems.reduce((total, item) => total + 1.12 * (item.quantity * item.price), 0).toFixed(2);
  };

  const handleDiscountChange = (e) => {
    setDiscount(parseFloat(e.target.value));
  };

  const calculateDiscount = () => {
    return ((calculateTotal() / 100) * discount).toFixed(2);
  };

  const calculateNetAmount = () => {
    return (calculateTotal() - calculateDiscount()).toFixed(2);
  };

  const handleSave = () => {
    const invoiceData = {
      invoiceNumber: invoiceNumber.toString(),
      customerName: invoiceDetails.customerName,
      address: invoiceDetails.address,
      date: new Date(invoiceDetails.invoicedate).getTime() / 1000,
      finalAmount: calculateNetAmount(),
      medicines: invoiceItems.map(item => ({
        medicineId: "1234",
        name: item.description,
        qty: item.quantity,
        price: item.price,
        totalAmount: (1.12 * item.quantity * item.price).toFixed(2)
      }))
    };

    dispatch(invoicethunk(invoiceData));

    // Save the current invoice number to local storage
    localStorage.setItem("lastInvoiceNumber", invoiceNumber.toString());

    // Increment the invoice number for the next invoice
    setInvoiceNumber(prev => prev + 1);
  };

  return (
    <div className="custom-polifyx-scrollbar border-r-4 h-full pr-2">
      <span className="flex justify-center">
        <img src={Logo} className="w-32 h-32 mb-2" alt="Logo" />
      </span>
      <Typography variant="h4" className="mb-2 text-center py-2">
        IDEAL Hair Clinic
      </Typography>
      <Typography variant="h5" className="mb-3 px-5">
        Bill To:
      </Typography>
      <div className="flex flex-wrap justify-between mb-4">
        <div className="flex flex-col gap-3 w-full sm:w-2/6 ml-3">
          <Input className="w-full" label="Customer Name" name="customerName" value={invoiceDetails.customerName} onChange={handleInvoiceDetailChange} />
          <Input className="w-full" label="Address" name="address" value={invoiceDetails.address} onChange={handleInvoiceDetailChange} />
          <Input className="w-full" label="Mobile" name="mobile" value={invoiceDetails.mobile} onChange={handleInvoiceDetailChange} />
        </div>
        <div className="flex flex-col gap-3 w-full sm:w-2/6">
          <Typography variant="h6" className="mb-2 px-1">
            Invoice Id: {invoiceNumber}
          </Typography>
          <Input className="w-full" label="InvoiceDate" type="date" name="invoicedate" value={invoiceDetails.invoicedate} onChange={handleInvoiceDetailChange} />
          <Input className="w-full" label="DueDate" type="date" name="duedate" value={invoiceDetails.duedate} onChange={handleInvoiceDetailChange} />
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Serial No.</th>
              <th className="px-4 py-2">Item Description</th>
              <th className="px-4 py-2">Quantity</th>
              <th className="px-4 py-2">Unit Price</th>
              <th className="px-4 py-2">Amount</th>
              <th className="px-4 py-2">TAX</th>
              <th className="px-4 py-2">Total</th>
              <th className="px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {invoiceItems.map((item, index) => (
              <tr key={index}>
                <td className="pl-4 sm:pl-14 py-2">{index + 1}</td>
                <td>
                  <CreatableSelect isClearable onChange={(newValue) => handleSelectChange(index, newValue)} onCreateOption={(inputValue) => handleCreate(index, inputValue)} options={options} value={options.find((option) => option.label === item.description)} />
                </td>
                <td>
                  <Input name="quantity" type="number" value={item.quantity} onChange={(e) => handleItemChange(index, "quantity", parseInt(e.target.value, 10))} />
                </td>
                <td>
                  <Input name="price" type="number" value={item.price} onChange={(e) => handleItemChange(index, "price", parseFloat(e.target.value))} />
                </td>
                <td className="pl-4 sm:pl-14">{item.price * item.quantity}</td>
                <td className="pl-4 sm:pl-14">{(0.12 * item.quantity * item.price).toFixed(2)}</td>
                <td className="pl-4 sm:pl-14">{(1.12 * item.quantity * item.price).toFixed(2)}</td>
                <td className="pl-4 sm:pl-14">
                  <Button color="red" onClick={() => removeItem(index)}>
                    <FaTrash />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Button color="green" onClick={addItem} className="mt-4">
        +
      </Button>
      <div className="flex flex-col items-end mt-4 mr-10">
        <Typography variant="h4">Total Amount: ₹{calculateTotal()}</Typography>
        <div className="w-30 mb-4 mt-2">
          <Input label="Discount (%)" type="number" value={discount} onChange={handleDiscountChange} className="w-1/2" />
        </div>
        <Typography variant="h4">Discount: ₹{calculateDiscount()}</Typography>
        <Typography variant="h4">Net Amount: ₹{calculateNetAmount()}</Typography>
      </div>
      <div className="flex justify-end mt-4 mr-10">
        <Button color="green" className="mr-4" onClick={handleSave}>
          Save
        </Button>
        <Button color="deep-orange">Generate</Button>
      </div>
    </div>
  );
};

export default Invoice;
